body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; /* Hide scrollbars */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.picRowItem {
  overflow: hidden;
  border-top: solid black 0px;

}

.select {
  width: 25%;
  text-align: center;
}

.infinite-scroll-component__outerdiv {
  width: 100% !important
}

.resultsParent {
  overflow: auto;
  position: absolute;
  bottom: 10;
  right: 10px;
  left: 0px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* hide scrollbar for chrome, safari and opera */
.resultsParent::-webkit-scrollbar {
  display: none;
}

.resultsParent p {
  color: #fff;
  padding: 20px;
}




